

.container-body {
  margin: 2em auto 5em;

  }
  
  .bg-container {
    position: relative;
    width: 80% !important;
    margin: auto;
    margin-top: 5em;
    text-align: center;


  }
  .bg-container img {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin:auto;
    font-size: 70px;
    margin-bottom: 1em;
    flex-shrink: 2;
  }

